import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoginForm } from '../interfaces/login-form.interface';
import { Usuario } from '../models/usuario.models';


const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private _ocultarModal: boolean = true;
  public tituloModal: string = "Titulo en el servicio";
  private _userId: number;

  public UsuarioForm = this.fb.group({
    nombre: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    password: [''],
    telefono: ['', Validators.required],
    direccion: ['', Validators.required],
    rol: ['', Validators.required],
  } );

  get ocultarModal(){
    return this._ocultarModal;
  }

  get rol(): string{
    const rol = localStorage.getItem('rol') || '';
    return rol
  }

  get id(): string{
    const id = localStorage.getItem('id') || '';
    return id
  }

  get nombre(): string{
    const nombre = localStorage.getItem('nombre') || '';
    return nombre
  }

  get headers(){
    return {
      headers: {
        Authorization: this.token
      }
    }
  }

  get token(): string{
    const token = 'Bearer ' + (localStorage.getItem('token') || '');
    return token
  }

  constructor( private http: HttpClient,
               private router: Router,
               private fb: FormBuilder, ) { }

  logout( ){
    
    this.http.post( `${ base_url }/logout`, {email: localStorage.getItem('email') }, this.headers ).subscribe();
    localStorage.removeItem('token');
    localStorage.removeItem('nombre');
    localStorage.removeItem('email');
    localStorage.removeItem('rol');
    localStorage.removeItem('id');
    localStorage.removeItem('cambio');
    this.router.navigateByUrl('/login');
  }

  login( formData: LoginForm ){
    return this.http.post( `${ base_url }/login`, formData )
                .pipe(
                  tap ( (resp: any) => {
                    localStorage.setItem('token', resp.token);
                    localStorage.setItem('nombre', resp.nombre);
                    localStorage.setItem('email', resp.email);
                    localStorage.setItem('id', resp.id);
                    localStorage.setItem('rol', resp.rol);
                  })
                )
  }

  estaAutenticado(){
    let token = this.token;
    let rol = this.rol;
    let nombre = this.nombre;
    
    if(token.length > 7 && rol != '' && nombre != ''){
      return true
    }else{
      return false
    }
  }

  cargarUsuarios(){
    const url = `${base_url}/users`; 
    return this.http.get<{ data: Usuario[] }>(url, this.headers )
  }

  cargarCobradores(){
    const url = `${base_url}/getcobradores`; 
    return this.http.get(url, this.headers )
  }

  buscarUsuario(id: number){
    const url = `${base_url}/users/${id}`; 
    return this.http.get(url, this.headers )
  }

  crearUsuario(  ){
    return this.http.post( `${ base_url }/users`, this.UsuarioForm.value , this.headers )
                .pipe(
                  tap ( (resp: any) => {
                  })
                )
  }

  editarUsuario(){
    return this.http.put( `${ base_url }/users/${this._userId}`, this.UsuarioForm.value, this.headers )
                .pipe(
                  tap ( (resp: any) => {
                  })
                )    
  }

  eliminarUsuario(usuario: Usuario){
    console.log('Eliminando este usuario: ' + usuario.nombre + ' id: ' + usuario.id);
    return this.http.delete( `${ base_url }/users/${usuario.id}`, this.headers )
                .pipe(
                  tap ( (resp: any) => {
                  })
                )
  }

  setUsuario(usuario: Usuario){
    this.UsuarioForm.setValue({
      nombre: usuario.nombre,
      email: usuario.email,
      password: this.UsuarioForm.get('password').value,
      telefono: usuario.telefono,
      direccion: usuario.direccion,
      rol: usuario.rol,
    });

    this._userId = usuario.id;
  }

  cleanUsuarioForm(){
    this.UsuarioForm.reset();
    this._userId = null;
  }

  /* Modal service  */
  
  abrirModal(titulo: string){
    this.tituloModal = titulo;
    this._ocultarModal = false;
  }

  cerrarModal(){
    this.cleanUsuarioForm();
    this._ocultarModal = true;
  }

  /* ############# */

  autorizacion(){
    if(this.rol != 'Administrador'){
      return false
    }else{
      return true
    }
  }


  /* ############# */
  
  getTipoCambio(){
    const url = `${base_url}/getTipoCambio`; 
    return this.http.get(url)
  }

  

}
