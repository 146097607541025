export const navItemsV = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    name: 'Clientes',
    url: '/clientes',
    icon: 'icon-people'
  },
  {
    name: 'Ventas',
    url: '/ventas',
    icon: 'icon-basket',
    children: [
      {
        name: 'Listado Propiedades',
        url: '/ventas/listado',
        icon: 'icon-layers'
      },
      {
        name: 'Nueva Venta',
        url: '/ventas/nueva-venta',
        icon: 'icon-basket'
      },
    ]
  },
  {
    name: 'Contratos',
    url: '/contratos',
    icon: 'icon-folder-alt',
  },
];
