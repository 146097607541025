export const navItemsC = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    name: 'Cuotas',
    url: '/cuotas',
    icon: 'icon-book-open'
  },
  {
    name: 'Cobros',
    url: '/cobros',
    icon: 'icon-wallet',
    children: [
      {
        name: 'Cobros',
        url: '/cobros/cobros',
        icon: 'icon-list'
      },
      {
        name: 'Nuevo Cobro',
        url: '/cobros/nuevo-cobro',
        icon: 'icon-credit-card'
      },
      
    ]
  },
];
