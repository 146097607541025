import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UsuarioService } from '../../services/usuario.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
/* @Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']

}) */
export class LoginComponent {

  public formSubmitted = false;

  public loginForm = this.fb.group({
    email: [ localStorage.getItem('email') || '', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  } );


  constructor(private router: Router,
              private fb: FormBuilder,
              private usuarioService: UsuarioService) { }

  login(){
    
    this.usuarioService.login(this.loginForm.value)
    .subscribe( resp => {
      
      this.router.navigateByUrl('/dashboard');

    }, (err) => {
      Swal.fire('Error', '<strong>Datos incorrectos</strong>', 'error');
    })

  }

}