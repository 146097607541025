import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuarioService } from '../../services/usuario.service';
import { navItemsAC } from '../../_nav_admin_cobros';
import { navItemsC } from '../../_nav_cobrador';
import { navItemsV } from '../../_nav_vendedor';
import { navItems } from './../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public tipo_cambio = localStorage.getItem('cambio');
  public nombre_usuario = "";

  current_year: number;
  
  constructor( private usuarioService: UsuarioService,
              private router: Router,
               ) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }

  logOut(){
    this.usuarioService.logout();
  }

  getTipoCambio(){
    this.usuarioService.getTipoCambio()
    .subscribe( (resp) => {
      var text = resp.toString();
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(text,"text/xml");

      var dato = xmlDoc.getElementsByTagName("referencia")[0].childNodes[0].nodeValue;
      this.tipo_cambio = Number(dato).toFixed(2);
      localStorage.setItem('cambio', Number(dato).toFixed(2));
    })
      
  }


  ngOnInit() {
    let rol = this.usuarioService.rol;
    if(rol == 'Cobrador'){
      this.navItems = navItemsC;
    }else if(rol == 'Administrador de Cobros'){
      this.navItems = navItemsAC;
    }else if(rol == 'Vendedor'){
      this.navItems = navItemsV;
    }
    this.getTipoCambio();
    this.nombre_usuario = this.usuarioService.nombre;
    this.current_year = new Date().getFullYear()
  }
}
